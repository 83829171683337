<template>
  <div class="flex h-full grow flex-col">
    <BookingStepHeader class="mb-4 md:mb-0" />

    <main v-if="loading" class="grow px-4 py-12">
      <LoadingSpinner />
      <Guideline
        :title="loadingMessage.title"
        :text="loadingMessage.text"
        class="my-4"
      />
    </main>

    <main v-show="!loading" class="grow">
      <client-only>
        <slot />
      </client-only>
    </main>

    <G7Footer />
  </div>
</template>

<script setup lang="ts">
import type { BookingLayout } from "@/types/layout";
import type { Order } from "~~/types/order";

const { data } = await useGo7seasApi<BookingLayout>(
  "/catalog/homepages/go7seas"
);

onMounted(() => {
  updateBookingLayout(data.value as BookingLayout);
});

const loading: Ref<boolean> = useBookingLoading(true);
const booking: Ref<Order> = useBooking();

if (useAuth().valid.value) {
  const currentStep = useCurrentStep(useRoute().path);
  if (currentStep !== null) {
    const data = await useGo7seasClient<Order>("/orders/steps", {
      method: "GET",
    });

    booking.value = data as Order;
  }
}

const loadingMessage = computed(() => booking.value.loading_message);

onUnmounted(() => {
  setBookingFormId(null);
});
</script>
